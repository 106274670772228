/**
 * App
*/
import $ from "jquery";
import AOS from "../../node_modules/aos/dist/aos";
import navbar from "Components/navbar";
import 'Components/micromodal.js';

document.addEventListener('DOMContentLoaded', function() {
    document.getElementById('loading').style.display = 'none';

    window.$ = $;

    if( typeof console === 'object' ) {
        const flame = String.fromCodePoint(0x1F525);
        const heart = String.fromCodePoint(0x1F49C);

        console.log('%c%s',
        'color: white; background: black; font-size: 18px;',
        '\n' +
        ' Made with ' + heart +' by Empowers Studio ' + flame + '\n' +
        ' Designed & Developed by -@EmpowersStudio \n' +
        ' Visit us => https://twitter.com/EmpowersStudio ');
    }


    AOS.init({
        offset: -210,
    });

    navbar();

    $('a[href^="#"]').on('click',function(e) {
        e.preventDefault();
        var target = this.hash;
        var header = $('.header').height();
        var $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': ($target.offset().top - header)
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    });



    $('.accordion span').click(function(j) {
        var dropDown = $(this).closest('li').find('p');

        $(this).closest('.accordion').find('p').not(dropDown).slideUp();

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).closest('.accordion').find('span.active').removeClass('active');
            $(this).addClass('active');
        }

        dropDown.stop(false, true).slideToggle();

        j.preventDefault();
    });

    // Initialize ScrollMagic Controller
    /*eslint no-undef: "error"*/
    var controller = new ScrollMagic.Controller(); // eslint-disable-line
    var steps = document.querySelectorAll('.step'); // eslint-disable-line

    for( var step of steps ) { // eslint-disable-line
        var scene = new ScrollMagic.Scene({ // eslint-disable-line
            triggerElement: step, // eslint-disable-line
            triggerHook: 0.9, // eslint-disable-line
            duration: "95%" // eslint-disable-line
        })
        // This is where we are defining the toggle class
        .setClassToggle(step, 'is-active') // eslint-disable-line
        .addTo(controller) // eslint-disable-line
    }
    //  That is all there is to it really ;)

    'use strict';
});